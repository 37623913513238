import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [loadingDots, setLoadingDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => setLoadingDots(loadingDots === 3 ? 1 : loadingDots + 1),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [loadingDots]);

  const createDots = () => {
    let dots = '';
    for (let i = 0; i < loadingDots; i++) dots += '.';
    return dots;
  };

  return (
    <div className={'flex h-screen flex-col justify-center items-center'}>
      <img alt="spinner" className={'px-auto'} src={'/images/preloader_tp.gif'} />
      <h1 className={'text-gray-800 text-center pt-6 font-bold text-xl'}>Test Perfect</h1>
      <p>Loading {createDots()}</p>
    </div>
  );
};

export default Loader;
