import Joi from "joi";
import {isValidPhoneNumber} from "libphonenumber-js";

export const onboardinRequestSchema = Joi.object({
    email: Joi.string()
        .email({tlds: {allow: false}}).required(),
    password: Joi.string().min(8).max(255).required(),
    companyName: Joi.string().min(2).max(255).required(),
    firstName: Joi.string().min(2).max(20).required(),
    lastName: Joi.string().min(2).max(20).required(),
    phoneNumber: Joi
        .string()
        .custom((value, helper) => {
            if (!isValidPhoneNumber(value)) {
                return helper.message({
                    custom: 'Telefonnummer ist nicht gültig',
                    code: 'any.phonenUmber',
                    details: value
                });
            }
            return value

        }).required(),
    street: Joi.string().min(2).max(255).required(),
    houseNumber: Joi.string().min(1).max(255).required(),
    city: Joi.string().min(2).max(80).required(),
    zipcode: Joi.string().regex(/^\d+$/).min(5).max(5).required(),
}).required()

export const acceptInvitationSchema = Joi.object({
    password: Joi.string().min(8).max(255).required(),

}).required()