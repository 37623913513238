import React from 'react';
import {useTranslate} from "@tolgee/react";

const Header = () => {
    const t = useTranslate()
    return (

        <div className={"bg-brand-500 text-white h-64 flex"}>
            <div className={"bg-site-image bg-no-repeat bg-bottom bg-fixed bg-cover w-full "}>
                <img className={"w-64 p-4-"} src={"/images/logo_test_perfect_web_white.png"} alt={"logo"} />
                <div className={"flex"}>
                  <div className={"m-auto"}>
                      <h1 className={"text-center text-4xl py-8 font-bold"}>{t("HEADER_TITLE")}</h1>
                     <div className={"text-center invisible md:visible"}>
                         <p>{t("HEADER_LINE_1")}</p>
                         <p>{t("HEADER_LINE_2")}</p>
                     </div>
                  </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
