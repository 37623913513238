import React, {useEffect, useState} from 'react';
import {useTranslate} from "@tolgee/react";
import {Field, Input, Label, Message} from '@zendeskgarden/react-forms';
import {
    Autocomplete,
    Dropdown,
    Field as DropDownField,
    Item,
    Label as DropDownLabel,
    Menu
} from '@zendeskgarden/react-dropdowns';

import {useAddressService} from "../../utils/useAddressService";
import {Inline} from "@zendeskgarden/react-loaders";

export interface AddressFormSectionProps {
    onFieldInputChange: (name: string, value: string) => void;
    errorState: any
}

export interface AddressState {
    zipcode: string,
    city?: string
    street?: string
    houseNumber?: string
}

const AddressFormSection = ({onFieldInputChange, errorState}: AddressFormSectionProps) => {
    const t = useTranslate()
    const [state, setState] = useState<AddressState>({
        zipcode: "",
        city: undefined,
        street: undefined,
        houseNumber: undefined
    });

    const [cityArray, setCityArray] = useState<string[]>([]);
    const [streetArray, setStreetArray] = useState<string[]>([]);
    const [matchingOptionsStreet, setMatchingOptionsStreet] = useState<string[]>([]);
    const {addressData, fetch, loading} = useAddressService()

    const [inputValueStreet, setInputValueStreet] = useState("");


    useEffect(() => {
        let cityArraySorted = addressData?.streets.map((street) => street.communityName)
            .sort((a, b) => a.localeCompare(b)) ?? []
        cityArraySorted = Array.from(new Set(cityArraySorted))
        setState({...state, city: cityArraySorted[0]})
        onFieldInputChange("city", cityArraySorted[0])
        setCityArray(cityArraySorted)
    }, [addressData]);

    useEffect(() => {
        const filteredStreetsByCommunityName = (addressData?.streets ?? []).filter((item) => item.communityName === state.city)
        let streetArraySorted = filteredStreetsByCommunityName.map((street) => street.streetName)
            .sort((a, b) => a.localeCompare(b)) ?? []
        streetArraySorted = Array.from(new Set(streetArraySorted))
        setState({...state, street: streetArraySorted[0]})
        onFieldInputChange("street", streetArraySorted[0])
        setStreetArray(streetArraySorted)
        setMatchingOptionsStreet(streetArraySorted)
    }, [state.city]);

    useEffect(() => {
        if (addressData === undefined) {
            setStreetArray([])
            setCityArray([])
            setState({...state, city: undefined, street: undefined})

        }
    }, [addressData]);


    useEffect(() => {
        const matchedOptions = streetArray.filter(
            option => option.trim().toLowerCase().indexOf(inputValueStreet.trim().toLowerCase()) !== -1
        );
        setMatchingOptionsStreet(matchedOptions);
    }, [inputValueStreet]);

    const handleZipCodeChange = (zipcode: string) => {
        fetch(zipcode).then()
        setState({...state, zipcode: zipcode})
        onFieldInputChange("zipcode", zipcode)
    }
    return (
        <>
            <div style={{gridColumn: "span 2"}}>
                <Field>
                    <Label>{t("LABEL_ZIP_CODE")}</Label>
                    <Input type={"number"}
                           onBlur={(e) => {
                               handleZipCodeChange(e.target.value)
                           }}
                           onChange={(e) => {
                               if (e.target.value.length === 5)
                                   handleZipCodeChange(e.target.value)
                           }}
                           validation={errorState["zipcode"] ? "error" : undefined}
                    />
                    {!(cityArray.length > 0) && state.zipcode.trim() !== "" && !loading ?
                        <Message validationLabel={"error"}
                                 validation="error">{t("ERROR_ZIPCODE_WRONG")}</Message> : null}
                </Field>
            </div>
            <div style={{gridColumn: "span 4"}}>
                <Dropdown
                    selectedItem={state.city}
                    onSelect={item => {
                        setState({...state, city: item})
                        onFieldInputChange("city", `${item}`)
                    }}
                    downshiftProps={{defaultHighlightedIndex: 0}}
                >
                    <DropDownField>
                        <DropDownLabel>{t("LABEL_CITY")}{loading ? <Inline/> : null}</DropDownLabel>
                        <Autocomplete validation={errorState["city"] ? "error" : undefined} disabled={!(cityArray.length > 0)}>{state.city}</Autocomplete>
                    </DropDownField>
                    <Menu>
                        {cityArray && cityArray.length > 0 ? (
                            cityArray.map(option => (
                                <Item key={option} value={option}>
                                    <span>{option}</span>
                                </Item>
                            ))
                        ) : (
                            <Item disabled>{t("ERROR_ZIPCODE_WRONG")}</Item>
                        )}
                    </Menu>
                </Dropdown>
            </div>
            <div style={{gridColumn: "span 4"}}>
                <Dropdown
                    selectedItem={state.street}
                    onSelect={item => {
                        setState({...state, street: item})
                        onFieldInputChange("street", item)
                    }}
                    downshiftProps={{defaultHighlightedIndex: 0}}
                    inputValue={inputValueStreet}
                    onInputValueChange={(e) => setInputValueStreet(e)}

                >
                    <DropDownField>
                        <DropDownLabel>{t("LABEL_STREET")}{loading ? <Inline/> : null}</DropDownLabel>
                        <Autocomplete validation={errorState["street"] ? "error" : undefined} disabled={!(streetArray.length > 0)}>{state.street}</Autocomplete>
                    </DropDownField>
                    <Menu>
                        {matchingOptionsStreet && matchingOptionsStreet.length > 0 ? (
                            matchingOptionsStreet.map(option => (
                                <Item key={option} value={option}>
                                    <span>{option}</span>
                                </Item>
                            ))
                        ) : (
                            <Item disabled>{t("ERROR_ZIPCODE_WRONG")}</Item>
                        )}
                    </Menu>
                </Dropdown>
            </div>
            <div style={{gridColumn: "span 2"}}>
                <Field>
                    <Label>{t("LABEL_HOUSE_NUMBER")}</Label>
                    <Input disabled={!(streetArray.length > 0)}
                           onBlur={(e) => {
                               setState({...state, houseNumber: e.target.value})
                               onFieldInputChange("houseNumber", e.target.value)
                           }}
                           validation={errorState["houseNumber"] ? "error" : undefined}
                    />
                </Field>
            </div>
        </>);
};

export default AddressFormSection;
