import React from 'react';
import Header from "./components/Header/Header";
import {TolgeeProvider} from "@tolgee/react";
import {ThemeProvider} from '@zendeskgarden/react-theming';
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import OnboardForm from "./view/OnboardForm/OnboardForm";
import MemberInvitation from "./view/MemberInvitation/MemberInvitation";


export const App = () => {
    return (
        <TolgeeProvider
            staticData={{
                'de-DE': () => import('./i18n/de-DE.json')
            }}
            apiUrl={process.env.REACT_APP_TOLGEE_API_URL}
            apiKey={process.env.REACT_APP_TOLGEE_API_KEY}
            loadingFallback={<Loader/>}
            defaultLanguage={"de-DE"}
        >
            <ThemeProvider>
                <div className="App bg-gray-100 h-full">
                    <Header/>
                    <BrowserRouter>
                        <Routes>
                            <Route index element={<OnboardForm/>}/>
                            <Route path={"/accept-invitation/:code"} element={<MemberInvitation/>}/>
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </BrowserRouter>
                    <Footer/>
                </div>
            </ThemeProvider>
        </TolgeeProvider>
    );
}
