import React, {useState} from 'react';
import DataFormSection from "../../components/DataFormSection/DataFormSection";
import AddressFormSection from "../../components/AddressFormSection/AddressFormSection";
import {useTranslate} from "@tolgee/react";
import axios, {AxiosError} from "axios";
import {onboardinRequestSchema} from "./schemas";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {Spinner} from "@zendeskgarden/react-loaders";
import {ApiErrorResponse} from "../../types/ApiErrorResponse";

interface AddressDataInterface {
    street?: string
    city?: string
    zipcode?: string
    houseNumber?: string;
}

interface PersonDataInterface {
    companyName?: string,
    firstName?: string,
    lastName?: string
    phoneNumber?: string
}

interface AccountDataInterface {
    email?: string
    password?: string
}

export type OnboardingRequestData = AccountDataInterface & PersonDataInterface & AddressDataInterface;


const OnboardForm = () => {
    const t = useTranslate()

    const [globalState, setGlobalState] = useState<OnboardingRequestData>({});
    const [errorState, setErrorState] = useState<any>({});
    const [pending, setPending] = useState(false);
    const [success, setSuccess] = useState(false);
    //recaptcha
    const {executeRecaptcha} = useGoogleReCaptcha();
    const onSubmitClicked = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('startOnboardingProcess');
            await submit(token)
        }
    };

    const submit = async (recaptcha: string) => {
        setPending(true)
        const {error} = onboardinRequestSchema.validate(globalState, {
            abortEarly: false
        });
        if (error) {
            setPending(false)
            let tempErrorState: OnboardingRequestData = {}
            error.details.forEach((e) => {
                tempErrorState = {...tempErrorState, [e.path.toString()]: e.type}
            })
            setErrorState(tempErrorState)
            window.scrollTo(0, 0)
            return;
        }

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/public/onboarding`, {
            ...globalState,
            recaptcha
        })
            .then(() => setSuccess(true))
            .catch(async (e: AxiosError) => {
                setPending(false)
                if (e.response?.status === 409) {
                    setErrorState({...errorState, "email": (e.response?.data as ApiErrorResponse)?.code})
                    window.scrollTo(0, 0)
                } else {
                    alert(t("ERROR") + ": " + e.response?.status)
                }

            })

    }

    const fieldChange = (name: string, value: string) => {
        setGlobalState({
            ...globalState,
            [name]: value
        })
        setErrorState({
            ...errorState,
            [name]: undefined,
        })

    }

    if (success) {
        return <div className={"flex justify-center flex-col w-full"}>
            <h1 className={"text-4xl font-bold mx-auto py-12 text-gray-700"}>{t("ACCOUNT_CREATED")}</h1>
            <p className={"text-gray-700 mx-auto text-center"}
               dangerouslySetInnerHTML={{__html: t("ACCOUNT_CREATED_DESCRIPTION")}}></p>
            <img alt={"created"} className={"mx-auto w-1/4"}
                 src={"/images/success_image.webp"}/>
            <button
                className={`${pending ? "bg-primary-600" : "bg-primary-500"} text-white mx-auto w-3/4 lg:w-1/4 py-4 border rounded-3xl mt-16 hover:bg-primary-600 font-bold`}
                onClick={() => location.href = (process.env.REACT_APP_FRONTEND_URL ?? "https://app.testperfect.de")}>
                {t("GO_TO_APP_LINK")}
            </button>
        </div>
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <br/>
                <DataFormSection<PersonDataInterface>
                    title={"DATA_TITLE_PERSONAL_INFORMATION"}
                    description={"DATA_DESCRIPTION_PERSONAL_INFORMATION"}
                    onFieldInputChange={(name, value) => fieldChange(name, value)}
                    errorState={errorState}
                    fields={[
                        {
                            label: "LABEL_COMPANY_NAME",
                            colSpan: 6,
                            name: "companyName",
                            type: "text",
                        },
                        {
                            label: "LABEL_FIRSTNAME",
                            colSpan: 3,
                            name: "firstName",
                            type: "text",
                        },
                        {
                            label: "LABEL_LASTNAME",
                            colSpan: 3,
                            name: "lastName",
                            type: "text",
                        },
                        {
                            label: "LABEL_PHONE_NUMBER",
                            colSpan: 6,
                            name: "phoneNumber",
                            type: "text",
                        }
                    ]}/>
                <br/>
                <DataFormSection
                    title={"DATA_TITLE_ADDRESS"}
                    description={"DATA_DESCRIPTION_ADDRESS"}
                    errorState={errorState}
                >
                    <AddressFormSection
                        errorState={errorState}
                        onFieldInputChange={(name, value) => fieldChange(name, value)}
                    />
                </DataFormSection>
                <br/>
                <DataFormSection<AccountDataInterface>
                    title={"DATA_TITLE_ACCOUNT_INFORMATION"}
                    description={"DATA_DESCRIPTION_ACCOUNT_INFORMATION"}
                    onFieldInputChange={(name, value) => fieldChange(name, value)}
                    errorState={errorState}
                    fields={[
                        {
                            label: "LABEL_EMAIL",
                            colSpan: 6,
                            name: "email",
                            type: "text",
                        },
                        {
                            label: "LABEL_PASSWORD",
                            colSpan: 6,
                            name: "password",
                            type: "password",
                        }
                    ]}/>
                <div className={"py-12 text-center"}>
                    <p dangerouslySetInnerHTML={{__html: t("ACCEPT_TOS_DESCRIPTION")}}/>
                    <button
                        disabled={pending}
                        onClick={() => onSubmitClicked()}
                        className={`${pending ? "bg-primary-600" : "bg-primary-500"} text-white w-full py-4 border rounded-3xl mt-16 hover:bg-primary-600 font-bold`}>
                        {pending ?
                            <Spinner className={"mx-auto"} size={"18px"}/> : t("START_ONBOARDING_BUTTON").toUpperCase()}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OnboardForm;
