import React, {useEffect, useState} from 'react';
import DataFormSection from "../../components/DataFormSection/DataFormSection";
import {useTranslate} from "@tolgee/react";
import {Spinner} from "@zendeskgarden/react-loaders";
import axios, {AxiosError} from "axios";
import {useParams, useSearchParams} from "react-router-dom";
import {InvitationPublicInformationDTO} from "../../types/InvitationPublicResponseDTO";
import {ApiErrorResponse} from "../../types/ApiErrorResponse";
import {ReactComponent as WarningIcon} from "../../icons/warning.svg";
import {acceptInvitationSchema} from "../OnboardForm/schemas";

interface AccountStaticInformationInterface {
    firstName?: string
    lastName?: string
}

interface AccountDataInterface {
    email?: string
    password?: string
}

export type AccountData = AccountDataInterface & AccountStaticInformationInterface


const MemberInvitation = () => {
    const t = useTranslate()

    const {code} = useParams()
    const [searchParams] = useSearchParams();
    const [globalState, setGlobalState] = useState<{ password?: string }>({});
    const [errorState, setErrorState] = useState<any>({});
    const [success, setSuccess] = useState(false);

    const [pending, setPending] = useState(false);
    const [invitation, setInvitation] = useState<InvitationPublicInformationDTO>();
    const [invitationNotFound, setInvitationNotFound] = useState(false);

    const fetchInvitationFromServer = async () => {
        const email = searchParams.get("email")
        await axios.get<InvitationPublicInformationDTO>(`${process.env.REACT_APP_BACKEND_API}/public/invitation/${code}?email=${email}`).then(
            (res) => setInvitation(res.data)
        ).catch(
            (e: AxiosError) => {
                const errorResponse = e.response?.data as ApiErrorResponse
                if (e.response?.status === 400 && errorResponse.code === "INVITATION_CODE_DOES_NOT_EXIST_OR_EXPIRED") {
                    console.log(`Invitation not existing for ${email} with secret code ${code}`)
                    setInvitationNotFound(true)
                } else {
                    alert("Failed to request invitation. Status code " + e.status)
                }
            }
        )
    }

    useEffect(() => {
        fetchInvitationFromServer().then()
    }, []);


    const acceptInvitation = async () => {
        setPending(true)
        const {error} = acceptInvitationSchema.validate(globalState, {
            abortEarly: false
        });
        if (error) {
            setPending(false)
            let tempErrorState: { password?: string } = {}
            error.details.forEach((e) => {
                tempErrorState = {...tempErrorState, [e.path.toString()]: e.type}
            })
            console.log(tempErrorState)
            setErrorState(tempErrorState)
            window.scrollTo(0, 0)
            return;
        }

        const email = searchParams.get("email")
        await axios.post(`${process.env.REACT_APP_BACKEND_API}/public/invitation/${code}/accept?email=${email}`, {
            ...globalState,
        })
            .then(() => setSuccess(true))
            .catch(async (e: AxiosError) => {
                setPending(false)
                if (e.response?.status === 409) {
                    setErrorState({...errorState, "email": (e.response?.data as any)?.code})
                    window.scrollTo(0, 0)
                } else {
                    alert(t("ERROR") + ": " + e.response?.status)
                }

            })

    }

    const fieldChange = (name: string, value: string) => {
        setGlobalState({
            ...globalState,
            [name]: value
        })
        setErrorState({
            ...errorState,
            [name]: undefined,
        })

    }

    /**
     * Success - invitation accepted
     */
    if (success) {
        return <div className={"flex justify-center flex-col w-full"}>
            <h1 className={"text-4xl font-bold mx-auto py-12 text-gray-700"}>{t("INVITATION_SUCCESSFUL_ACCEPTED")}</h1>
            <p className={"text-gray-700 mx-auto text-center"}
               dangerouslySetInnerHTML={{__html: t("INVITATION_SUCCESSFUL_ACCEPTED_DESCRIPTION")}}></p>
            <img alt={"created"} className={"mx-auto w-1/4"}
                 src={"/images/success_image.webp"}/>
            <button
                className={`${pending ? "bg-primary-600" : "bg-primary-500"} text-white mx-auto w-3/4 lg:w-1/4 py-4 border rounded-3xl mt-16 hover:bg-primary-600 font-bold`}
                onClick={() => location.href = (process.env.REACT_APP_FRONTEND_URL ?? "https://app.testperfect.de")}>
                {t("GO_TO_APP_LINK")}
            </button>
        </div>
    }

    /**
     * Display spinner - waiting for api request with invitation information
     */
    if (invitation === undefined && !invitationNotFound) {
        return <div className={"w-full flex flex-col items-center py-12"}>
            <Spinner size={"128px"}/>
            <h2 className={"font-bold text-2xl pt-2"}>{t("CHECK_INVITATION_VALID")}</h2>
        </div>
    }

    /**
     * Display expired or invalid information
     */
    if (invitation === undefined && invitationNotFound) {
        return <div className={"w-full flex flex-col items-center py-12"}>
            <WarningIcon fill="orange" className={"w-24"}/>
            <h2 className={"font-bold text-2xl pt-2"}>{t("INVITATION_INVALID_OR_EXPIRED")}</h2>
            <p>{t("HELP_INVITATION_INVALID_OR_EXPIRED")}</p>
        </div>
    }


    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <br/>

                <DataFormSection<AccountData>
                    title={t("INVITATION_TITLE_ACCOUNT_INFORMATION")}
                    description={
                        <p className={"my-8 text-sm text-gray-500"}>
                            {t("INVITATION_DESCRIPTION_ACCOUNT_INFORMATION", {
                                companyName: invitation?.inviterName ?? "anonymous"
                            })}
                        </p>}
                    onFieldInputChange={(name, value) => fieldChange(name, value)}
                    errorState={errorState}
                    fields={[
                        {
                            label: "LABEL_FIRSTNAME",
                            colSpan: 3,
                            name: "firstName",
                            readOnly: true,
                            isBare: true,
                            defaultValue: invitation?.firstName,
                            type: "text",
                        },
                        {
                            label: "LABEL_LASTNAME",
                            colSpan: 3,
                            name: "lastName",
                            readOnly: true,
                            isBare: true,
                            defaultValue: invitation?.lastName,
                            type: "text",
                        },
                        {
                            label: "LABEL_EMAIL",
                            colSpan: 6,
                            name: "email",
                            readOnly: true,
                            isBare: true,
                            defaultValue: invitation?.email,
                            type: "text",
                        },
                        {
                            label: "LABEL_PASSWORD",
                            colSpan: 6,
                            name: "password",
                            type: "password",
                        }
                    ]}/>
                <div className={"py-12 text-center"}>
                    <p dangerouslySetInnerHTML={{__html: t("ACCEPT_TOS_DESCRIPTION")}}/>
                    <button
                        disabled={pending}
                        onClick={() => acceptInvitation()}
                        className={`${pending ? "bg-primary-600" : "bg-primary-500"} text-white w-full py-4 border rounded-3xl mt-16 hover:bg-primary-600 font-bold`}>
                        {pending ?
                            <Spinner className={"mx-auto"} size={"18px"}/> : t("START_ONBOARDING_BUTTON").toUpperCase()}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MemberInvitation;
