import React, {useState} from 'react';
import {Field, Input, Label, Message} from "@zendeskgarden/react-forms";
import {useTranslate} from "@tolgee/react";
import {FieldType} from "./DataFormSection";

export interface InputFieldProps {
    label: string
    type: FieldType
    readOnly: boolean
    isBare: boolean
    defaultValue?: string
    onChange: (value: string) => void
    error?: string
}

const InputField = (field: InputFieldProps) => {
    const t = useTranslate()

    const [value, setValue] = useState<string | undefined>();
    return (
        <Field>
            <Label>{t(field.label)}</Label>
            <Input type={field.type}
                   onChange={(e) => {
                       setValue(e.target.value)
                       field.onChange(e.target.value)

                   }}

                   readOnly={field.readOnly}
                   isBare={field.isBare}
                   defaultValue={field.defaultValue}
                   value={field.defaultValue ?? value}
                   required={true}
                   validation={field?.error ? "error" : undefined}
            />
            {field?.error ? <Message validationLabel="error" validation="error">{t(`${field?.error}`)}</Message> : null}
        </Field>
    );
};

export default InputField;
