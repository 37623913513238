import React from 'react';
import {useTranslate} from "@tolgee/react";
import {Schema} from "joi";
import InputField from "./InputField";

export type FieldType = "text" | "number" | "password"

export interface FieldProps<T> {
    name: keyof T;
    type: FieldType
    label: string;
    readOnly?: boolean
    isBare?: boolean
    defaultValue?: string
    validation?: Schema
    colSpan?: number
}

export interface DataSection<T> {
    title: string;
    description: string | React.ReactElement
    onFieldInputChange?: (name: keyof T, value:string) => void;
    fields?: FieldProps<T>[];
    children?: React.ReactElement
    errorState: any
}

const DataFormSection = <T, >(props: DataSection<T>) => {
    const {title, description, fields, children, onFieldInputChange, errorState} = props;
    const t = useTranslate()

    return (
        <form className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
                <div>
                    <h2 className="text-2xl font-bold leading-6 text-gray-900">{t(title)}</h2>
                    { (typeof  description == "string") ?
                        <p className="mt-1 text-sm text-gray-500">{t(description)}</p> :
                        description
                    }
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    {fields?.map((field: FieldProps<T>, i) => (
                            <div key={i} style={{gridColumn: `span ${field.colSpan ? `${field.colSpan}` : "6"}`}}>
                                <InputField
                                    defaultValue={field.defaultValue}
                                    readOnly={field.readOnly ?? false}
                                    isBare={field.isBare ?? false}
                                    type={field.type}
                                    label={field.label}
                                    onChange={(value) => onFieldInputChange ? onFieldInputChange(field.name, value) : null}
                                    error={errorState[field.name]}
                                />
                            </div>
                        )
                    )}
                    {children}
                </div>
            </div>
        </form>
    );
};

export default DataFormSection;
